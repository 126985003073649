import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  bs: {
    translation: {
      "Dobro došli u Airsoft klub Bosna": "Dobrodošli u Airsoft klub Bosna",
    }
  },

  en: {
    translation: {
      "Dobro došli u Airsoft klub Bosna": "Welcome to Airsoft club Bosnia",
      "Dobro došli": "Welcome",
      "u Airsoft klub": "to Airsoft club",
      "Bosna": "Bosnia",
      "Prijava" : "Log in",
      "Korisničko ime" : "User name",
      "Šifra" : "Password",
      "Uloguj se" : "Log in",
      "Nemaš nalog" : "Don't have account",
      "Postani član" : "Become member",
      "POČETNA" : "HOME",
      "NAŠ TIM" : "OUR TEAM",
      "POSTANI ČLAN" : "BECOME MEMBER",
      "O NAMA" : "ABOUT US",
      "DONACIJA" : "DONATION",
      "Naši članovi" : "Our members",
      "Pridružite se našem timu" : "Join our team",
      "Želim se prijaviti kao" : "I wish to join as",
      "redovan igrač" : "regular player",
      "povremeni igrač" : "periodical player",
      "Ime" : "Name",
      "Prezime": "Surname",
      "Telefon": "Phone",
      "Priloženi dokument o nekažnjavanju" : "Attached document on impunity",
      "Više o meni" : "More about me",
      "korisnik@mail.com" : "user@mail.com",
      "O nama": "About us",
      "AIRSOFT KLUB BOSNA je sve što Vam treba" : "AIRSOFT CLUB BOSNIA is everything you need",
      "Mi smo novoformirano udruženje koje prikuplja nove članove. Ovo udruženje ima visoke ambicije i cilj da pokrene mlade kroz fizički razvoj i psihičku relaksaciju" : "We are a newly formed association that is gathering new members. This association has high ambitions and aims to motivate young people through physical development and psychological relaxation",
      "Naš glavni cilj je druženje i pružanje značajnog doprinosa zajednici. Svi ljubitelji sporta su dobrodošli! Kroz udruženje želimo stimulisati mlade ljude da se bave sportom i međusobno druže" : "Our main goal is to socialize and make a significant contribution to the community. All sports fans are welcome! Through the association, we want to stimulate young people to play sports and socialize with each other",
      "Kontaktirajte nas" : "Contact us",
      "Zapratite nas" : "Follow us",
      "E-mail adresa" : "E-mail adress",
      "© 2024 Airsoft klub Bosna" : "© 2024 Airsoft club Bosnia",
      "Naši planovi" : "Our plans",
      "Obzirom da smo novo udruženje koje se tek razvija i planira, blagovremeno ćemo Vas informisati putem naše web platforme kao i društvenih mreža s svim aktivnostima koje provodimo" : "Considering that we are a new association that is still developing and planning, we will inform you in a timely manner via our web platform as well as social networks with all the activities we carry out",
      "Blagovremeno će biti i medijski sadržaj kroz koji ćemo prezentovati naše igre i aktivnosti" : "In due course, there will be media content through which we will present our games and activities",
      "Donacija" : "Donation",
      "Napravimo zajedno dobre stvari! Ukoliko prepoznate u nama nešto novo i pozitivno, što mjenja zajednicu i čini nas sviju boljima i vrijednijima, zbog čega nas ne podržati" : "Let's make good things together! If you recognize something new and positive in us, which changes the community and makes us all better and more valuable, why not support us",
      "Ukoliko se odlučite pomoći našem udruženju, to možete učiniti putem donacija ili aktivnim članstvom, što bi nam pričinilo veliko zadovoljstvo" : "If you decide to help our association, you can do it through donations or active membership, which would give us great pleasure",
      "Kontaktirajte nas za više informacija" : "Contact us for more information",
      "Registruj se" : "Register",
       "Hvala na prijavi": "Thank you for signing up",
       "Uspešno ste poslali zahtev. Uskoro ćemo vas kontaktirati" : "You have successfully submitted your request",
       "Odaberi tip članstva" : "Choose membership type",
       "Uskoro ćemo vas kontaktirati" : "We will contact you shortly",
       "O nama - ASK Bosna" : "About us - ASK Bosnia",
       "Naš tim - ASK Bosna" : "Our team - ASK Bosnia",
       "Postani član - ASK Bosna" : "Become member - ASK Bosnia",
       "Donacija - ASK Bosna" : "Donation - ASK Bosnia",
       "Uspešno ste poslali zahtev" : "You have successfully submitted your request",
       "Neispravna email adresa." : "Invalid email adress.",
       "Fajl je prevelik. Maksimalna veličina je 5MB." : "File is too big. Maximum allowed size is 5MB."
      
    }
  },
  sr: {
    translation: {

        "Dobro došli u Airsoft klub Bosna" : "Добро дошли у Airsoft клуб Босна",
        "Dobro došli": "Добро дошли",
        "u Airsoft klub": "у Airsoft клуб",
        "Bosna": "Босна",
        "Prijava": "Пријава",
        "Korisničko ime": "Корисничко име",
        "Šifra": "Шифра",
        "Uloguj se": "Улогуј се",
        "Nemaš nalog": "Немаш налог",
        "Postani član": "Постани члан",
        "POČETNA": "ПОЧЕТНА",
        "NAŠ TIM": "НАШ ТИМ",
        "POSTANI ČLAN": "ПОСТАНИ ЧЛАН",
        "O NAMA": "О НАМА",
        "DONACIJA": "ДОНАЦИЈА",
        "Naši članovi": "Наши чланови",
        "Pridružite se našem timu": "Придружите се нашем тиму",
        "Želim se prijaviti kao": "Желим се пријавити као",
        "redovan igrač": "редован играч",
        "povremeni igrač": "повремени играч",
        "Ime": "Име",
        "Prezime": "Презиме",
        "Telefon": "Телефон",
        "Priloženi dokument o nekažnjavanju": "Приложени документ о неосуђиваности",
        "Više o meni": "Више о мени",
        "korisnik@mail.com": "корисник@mail.com",
        "O nama": "О нама",
        "AIRSOFT KLUB BOSNA je sve što Vam treba": "AIRSOFT КЛУБ БОСНА је све што вам треба",
        "Mi smo novoformirano udruženje koje prikuplja nove članove. Ovo udruženje ima visoke ambicije i cilj da pokrene mlade kroz fizički razvoj i psihičku relaksaciju": "Ми смо новоосновано удружење које прикупља нове чланове. Ово удружење има велике амбиције и циљ да мотивише младе кроз физички развој и психичку релаксацију",
        "Naš glavni cilj je druženje i pružanje značajnog doprinosa zajednici. Svi ljubitelji sporta su dobrodošli! Kroz udruženje želimo stimulisati mlade ljude da se bave sportom i međusobno druže": "Наш главни циљ је социјализација и пружање значајног доприноса заједници. Сви љубитељи спорта су добродошли! Кроз удружење желимо да подстакнемо младе да се баве спортом и друже се",
        "Kontaktirajte nas": "Контактирајте нас",
        "Zapratite nas": "Запратите нас",
        "E-mail adresa": "Е-маил адреса",
        "© 2024 Airsoft klub Bosna": "© 2024 Airsoft клуб Босна",
        "Naši planovi": "Наши планови",
        "Obzirom da smo novo udruženje koje se tek razvija i planira, blagovremeno ćemo Vas informisati putem naše web platforme kao i društvenih mreža s svim aktivnostima koje provodimo": "С обзиром да смо ново удружење које се тек развија и планира, обавестићемо вас на време преко наше веб платформе, као и друштвених мрежа о свим активностима које спроводимо",
        "Blagovremeno će biti i medijski sadržaj kroz koji ćemo prezentovati naše igre i aktivnosti": "Благовремено ће бити и медијског садржаја кроз који ћемо представити наше игре и активности",
        "Donacija": "Донација",
        "Napravimo zajedno dobre stvari! Ukoliko prepoznate u nama nešto novo i pozitivno, što mjenja zajednicu i čini nas sviju boljima i vrijednijima, zbog čega nas ne podržati": "Направимо заједно добре ствари! Уколико у нама препознате нешто ново и позитивно, што мења заједницу и чини нас свима бољима и вреднијима, зашто нас не подржати",
        "Ukoliko se odlučite pomoći našem udruženju, to možete učiniti putem donacija ili aktivnim članstvom, što bi nam pričinilo veliko zadovoljstvo": "Ако се одлучите да помогнете нашем удружењу, то можете учинити путем донација или активним чланством, што би нам причинило велико задовољство",
        "Kontaktirajte nas za više informacija": "Контактирајте нас за више информација",
        "Registruj se" : "Региструуј се",
        "Hvala na prijavi" : "Хвала на пријави",
        "Uspešno ste poslali zahtev. Uskoro ćemo vas kontaktirati" : "Успешно сте послали захтев",
        "Odaberi tip članstva" : "Одабери тип чланства",
        "Uskoro ćemo vas kontaktirati" : "Ускоро ћемо вас контактирати",
        "O nama - ASK Bosna" : "О нама - ASK Босна",
        "Naš tim - ASK Bosna" : "Наш тим - ASK Босна",
        "Postani član - ASK Bosna" : "Постани члан - ASK Босна",
        "Donacija - ASK Bosna" : "Донација - ASK Босна",
        "Uspešno ste poslali zahtev" : "Успешно сте послали захтев",
        "Neispravna email adresa." : "Неисправна мејл адреса.",
        "Fajl je prevelik. Maksimalna veličina je 5MB." : "Фајл је превелик. Максимална величина је 5MB."
    }
  },
  de: {
    translation: {

        "Dobro došli u Airsoft klub Bosna" : "Willkommen im Airsoft-Verein Bosnien",   
        "Dobro došli": "Willkommen",
        "u Airsoft klub": "im Airsoft-Verein",
        "Bosna": "Bosnien",
        "Prijava": "Anmeldung",
        "Korisničko ime": "Benutzername",
        "Šifra": "Passwort",
        "Uloguj se": "Einloggen",
        "Nemaš nalog": "Hast du kein Konto",
        "Postani član": "Werde Mitglied",
        "POČETNA": "STARTSEITE",
        "NAŠ TIM": "UNSER TEAM",
        "POSTANI ČLAN": "WERDE MITGLIED",
        "O NAMA": "ÜBER UNS",
        "DONACIJA": "SPENDE",
        "Naši članovi": "Unsere Mitglieder",
        "Pridružite se našem timu": "Treten Sie unserem Team bei",
        "Želim se prijaviti kao": "Ich möchte mich als",
        "redovan igrač": "regulärer Spieler",
        "povremeni igrač": "gelegentlicher Spieler",
        "Ime": "Name",
        "Prezime": "Nachname",
        "Telefon": "Telefon",
        "Priloženi dokument o nekažnjavanju": "Beigefügtes Dokument über Straflosigkeit",
        "Više o meni": "Mehr über mich",
        "korisnik@mail.com": "benutzer@mail.com",
        "O nama": "Über uns",
        "AIRSOFT KLUB BOSNA je sve što Vam treba": "AIRSOFT CLUB BOSNIA ist alles, was Sie brauchen",
        "Mi smo novoformirano udruženje koje prikuplja nove članove. Ovo udruženje ima visoke ambicije i cilj da pokrene mlade kroz fizički razvoj i psihičku relaksaciju": "Wir sind ein neu gegründeter Verein, der neue Mitglieder sammelt. Dieser Verein hat hohe Ambitionen und das Ziel, junge Menschen durch körperliche Entwicklung und psychische Entspannung zu motivieren.",
        "Naš glavni cilj je druženje i pružanje značajnog doprinosa zajednici. Svi ljubitelji sporta su dobrodošli! Kroz udruženje želimo stimulisati mlade ljude da se bave sportom i međusobno druže": "Unser Hauptziel ist es, Kontakte zu knüpfen und einen bedeutenden Beitrag zur Gemeinschaft zu leisten. Alle Sportbegeisterten sind willkommen! Durch den Verein möchten wir junge Menschen dazu anregen, Sport zu treiben und sich gegenseitig zu treffen.",
        "Kontaktirajte nas": "Kontaktieren Sie uns",
        "Zapratite nas": "Folgen Sie uns",
        "E-mail adresa": "E-Mail-Adresse",
        "© 2024 Airsoft klub Bosna": "© 2024 Airsoft Club Bosnien",
        "Naši planovi": "Unsere Pläne",
        "Obzirom da smo novo udruženje koje se tek razvija i planira, blagovremeno ćemo Vas informisati putem naše web platforme kao i društvenih mreža s svim aktivnostima koje provodimo": "Da wir ein neuer Verein sind, der sich noch entwickelt und plant, werden wir Sie rechtzeitig über unsere Web-Plattform sowie soziale Netzwerke über alle Aktivitäten informieren, die wir durchführen.",
        "Blagovremeno će biti i medijski sadržaj kroz koji ćemo prezentovati naše igre i aktivnosti": "Rechtzeitig wird es auch Medieninhalte geben, durch die wir unsere Spiele und Aktivitäten präsentieren werden.",
        "Donacija": "Spende",
        "Napravimo zajedno dobre stvari! Ukoliko prepoznate u nama nešto novo i pozitivno, što mjenja zajednicu i čini nas sviju boljima i vrijednijima, zbog čega nas ne podržati": "Lass uns gemeinsam Gutes tun! Wenn Sie in uns etwas Neues und Positives erkennen, das die Gemeinschaft verändert und uns alle besser und wertvoller macht, warum sollten Sie uns nicht unterstützen",
        "Ukoliko se odlučite pomoći našem udruženju, to možete učiniti putem donacija ili aktivnim članstvom, što bi nam pričinilo veliko zadovoljstvo": "Wenn Sie sich entscheiden, unserem Verein zu helfen, können Sie dies durch Spenden oder aktive Mitgliedschaft tun, was uns große Freude bereiten würde",
        "Kontaktirajte nas za više informacija": "Kontaktieren Sie uns für weitere Informationen",
        "Registruj se" : "Registrieren",
        "Hvala na prijavi" : "Vielen Dank für Ihre Anmeldung",
        "Uspešno ste poslali zahtev" : "Sie haben Ihre Anfrage erfolgreich übermittelt",
        "Odaberi tip članstva" : "Wählen Sie einen Mitgliedschaftstyp",
        "Uskoro ćemo vas kontaktirati" : "Wir werden uns in Kürze mit Ihnen in Verbindung setzen",
        "O nama - ASK Bosna" : "Über uns - ASK Bosnia",
        "Naš tim - ASK Bosna" : "Unser Team - ASK Bosnia",
        "Postani član - ASK Bosna" : "Werden Sie Mitglied – ASK Bosnia",
        "Donacija - ASK Bosna" : "Spende - ASK Bosnia",
        "Neispravna email adresa." : "Ungültige E-Mail-Adresse.",
        "Fajl je prevelik. Maksimalna veličina je 5MB." : "Die Datei ist zu groß. Die maximale Größe beträgt 5 MB."
        
      }
      
    }
  
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "bs", // Početni jezik
    fallbackLng: "bs",
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;
