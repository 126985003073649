import React from 'react';
import './Nastim.css';
import SmokeBackground from './Smoke.js';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import '../i18n.js';

export default function Nastim() {

    const { t } = useTranslation(); 

    return (

    <>

     <Helmet>
        <title>{t('Naš tim - ASK Bosna')}</title>
        <meta name="description" content="Informacije o našim članovima." />
        <meta name="keywords" content="Airsoft, Klub, Bosna, tim, članovi" />
     </Helmet>

     <main>
         <h1 className="title">{t('Naši članovi')}</h1>
         <div className="grid--container">

         </div>
         <SmokeBackground />
     </main>
    </>

    )
}