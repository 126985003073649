import React, { useState, useEffect } from 'react';
import Media from './FotoVideo.js';
import Scrolltext from './Scrolltext.js';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import i18n from 'i18next';
import '../i18n.js';

export default function Onama({ pauseMusic, playMusic }) {
    const { t } = useTranslation();

    const [content, setContent] = useState({ onamap: [] });

    useEffect(() => {
        const currentLanguage = i18n.language;
        fetch(`/sadrzaj/tekst/o-nama/o-nama-${currentLanguage}.json`)
          .then((response) => response.json())
          .then((data) => setContent(data))
          .catch((error) => console.error('Error fetching JSON:', error));
    }, [i18n.language]);
    
    return (
        <>
      <Helmet>
        <title>{t('O nama - ASK Bosna')}</title>
        <meta name="description" content="Informacije o našem timu i klubskim aktivnostima." />
        <meta name="keywords" content="Airsoft, Klub, Bosna, tim, planovi, kontakt" />
      </Helmet>
        <main className="o--nama--main">
            <h1 className="title">{t('O nama')}</h1>
            <div className="opis--o--nama">
            {content.onamap.map((paragraph, index) => (
            <p key={index}>{(paragraph)}</p>
          ))}
            </div>

            <Media 
              contentType = 'image'
              />

            <div className="nasi--planovi"> 
                <Scrolltext />
            </div>
            <div className="second--bck"></div>
            <div className="div--split">

            <Media 
              contentType = 'video'
              pauseMusic={pauseMusic}
              playMusic={playMusic}
              />

            </div>
            <div className="third--bck"></div>
            <footer className="contact">
                <div className="info">
                    <h1 style={{fontSize: '3em'}}>{t('Kontaktirajte nas')}</h1>
                    <div>
                        <p>{t('Zapratite nas')}:</p>
                        <a href="https://www.facebook.com/profile.php?id=61566963117974" target="_blank" rel="noreferrer"><img src="/images/facebook.svg" alt="fb-ico" className="soc--ico"/>&nbsp;</a>
                        <a href="https://instagram.com/askbosna" target="_blank" rel="noreferrer"><img src="/images/instagram.svg" alt="in-ico" className="soc--ico"/>&nbsp;</a>
                        <a href="https://tiktok.com" target="_blank" rel="noreferrer"><img src="/images/tiktok.svg" alt="tt-ico" className="soc--ico"/>&nbsp;</a>
                        <a href="https://discord.com" target="_blank" rel="noreferrer"><img src="/images/discord.svg" alt="dc-ico" className="soc--ico"/>&nbsp;</a>
                    </div>
                    <p>{t('E-mail adresa')}: info@askbosna.ba</p>
                    <p>Viber/WhatsApp: +491774657647</p> 
                    <p>Mobile: +387 61 814982</p>
                    <p>Novi Travnik 72290, Mehmeda spahe bb</p>
                    <p>{t('© 2024 Airsoft klub Bosna')}</p>
                </div>
                <img className="logo" src="/images/askbosna-logo.svg" onClick={() => window.location.href = '/'} alt="logo"/>
            </footer>
        </main>
        </>
    )
}
