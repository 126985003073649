import React, {useState, useEffect} from 'react';
import SmokeBackground from './Smoke.js';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import i18n from 'i18next';
import '../i18n.js';

export default function Donacija() {
    const { t } = useTranslation();

    const [content, setContent] = useState({ paragraphs: [] });

    useEffect(() => {
        const currentLanguage = i18n.language;
        fetch(`/sadrzaj/tekst/donacija/donacija-${currentLanguage}.json`)
          .then((response) => response.json())
          .then((data) => setContent(data))
          .catch((error) => console.error('Error fetching JSON:', error));
    }, [i18n.language]);
    
    return (

    <>

        <Helmet>
        <title>{t('Donacija - ASK Bosna')}</title>
        <meta name="description" content="Informacije o donacijama našem udruženju." />
        <meta name="keywords" content="Airsoft, Klub, Bosna, donacija" />
        </Helmet>


        <main>
            <SmokeBackground />
            <h1 className="title">{t('Donacija')}</h1>
            <div className="opis--o--nama">
             {content.paragraphs.map((paragraph, index) => (
            <p key={index}>{(paragraph)}</p>
          ))}
            </div>
        </main>

    </>
    );
}
