import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import FlagSelector from './FlagSelector';
import { useTranslation } from 'react-i18next'; 

export default function Navbar() {
    const { t } = useTranslation(); 
    const [isOpen, setIsOpen] = useState(false);

    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 50) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };

      useEffect(() => {
       
        window.addEventListener("scroll", handleScroll);
    
        
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
          const menu = document.querySelector('.navbar--list');
          const hamburger = document.querySelector('.hamburger');
    
          
          if (isOpen && !menu.contains(event.target) && !hamburger.contains(event.target)) {
            setIsOpen(false);
          }
        };
    
        
        document.addEventListener('click', handleClickOutside);
    
        
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, [isOpen]);

    return (
        <nav className={`navbar  ${scrolled ? 'scrolled' : ''}`}>
            <img className="logo" src="/images/askbosna-logo.svg" 
                 style={{ filter: 'drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5))' }} 
                 onClick={() => window.location.href = '/'} 
                 alt="Logo" 
                 />
            <div className="flag--selector">
                <FlagSelector />
            </div>
            <button className="hamburger" onClick={toggleMenu}>
                &#9776;
            </button>
            <ul className={`navbar--list ${isOpen ? 'open' : ''}`}>
                <li>
                    <NavLink to="/" onClick={closeMenu} className={({ isActive }) => isActive ? 'navbar--link--active' : 'navbar--link'}>
                        {t('POČETNA')} 
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/nas-tim" onClick={closeMenu} className={({ isActive }) => isActive ? 'navbar--link--active' : 'navbar--link'}>
                        {t('NAŠ TIM')} 
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/postani-clan" onClick={closeMenu} className={({ isActive }) => isActive ? 'navbar--link--active' : 'navbar--link'}>
                        {t('POSTANI ČLAN')} 
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/o-nama" onClick={closeMenu} className={({ isActive }) => isActive ? 'navbar--link--active' : 'navbar--link'}>
                        {t('O NAMA')} 
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/donacija" onClick={closeMenu} className={({ isActive }) => isActive ? 'navbar--link--active' : 'navbar--link'}>
                        {t('DONACIJA')} 
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}
